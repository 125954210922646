import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/myNiceScroll'
import {resizeScroll} from '../../assets/scripts/myNiceScroll'

// 首页pc端banner
//V整体移动,底部色块闪烁
var bannerswiper
!(function(){
  if($(window).width() > 1024){
    pcbannerInit()
  }else{
    bannerswiper = ''
  }
})()

function pcbannerInit(){
  bannerswiper = new Swiper(".index-banner .pc-swiper .mySwiper", {
    effect : 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 600,
    allowTouchMove: false,
    mousewheel: {
      releaseOnEdges: true,
    },
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    pagination: {
      el: ".index-banner .swiper-pagination",
      clickable:true
    },
    on:{
      init:function(swiper){
        $('.v-cont').fadeIn(200)
        $('.v-cont .v .green-line').css({
          backgroundColor:'#0ea292',
          boxShadow: '0 0 0px rgba(0,0,0,.4)',
          transform: 'skew(30deg) translateX(200px)',
        })
        $('.v-cont .v .green-line img').css({
          transform: 'skew(-30deg) translateX(calc(-50% - 100px))',
          opacity: 0
        })
        $('.v-cont .v .blue-line').css({
          backgroundColor:'transparent',
          boxShadow: '0 0 50px rgba(0,0,0,.4)',
          transform: 'skew(-30deg) translateX(-200px)'
        })
        $('.v-cont .v .blue-line img').css({
          transform: 'skew(30deg) translateX(calc(-50%))',
          opacity: 1
        })
        $('.v-cont .left-black-line').css({
          left:'27%',
          transform: 'skew(30deg) translateX(0)',
          opacity: 1
        })
        $('.v-cont .right-black-line').css({
          right:'0',
          transform: 'skew(-30deg) translateX(100%)',
          opacity: 0
        })
        $('.v-cont .bg-img').css({
          transform: 'translateX(-50%) translateY(30px)',
          opacity: 0
        })
      },
      transitionStart: function(swiper){
        $('.pc-swiper .common-search-hint').removeClass('show')
        $('.pc-swiper .common-search-container').find('input.search-input').val('')
        switch(this.activeIndex) {
          case 0:
            $('.v-cont .v').css({
              left: '67%'
            })
            $('.v-cont .v .green-line').css({
              backgroundColor:'#0ea292',
              boxShadow: '0 0 0px rgba(0,0,0,.4)',
              transform: 'skew(30deg) translateX(200px)',
              zIndex: 0,
            })
            $('.v-cont .v .green-line img').css({
              transform: 'skew(-30deg) translateX(calc(-50% - 100px))',
              opacity: 0
            })
            $('.v-cont .v .blue-line').css({
              backgroundColor:'transparent',
              boxShadow: '0 0 50px rgba(0,0,0,.4)',
              transform: 'skew(-30deg) translateX(-200px)'
            })
            $('.v-cont .v .blue-line img').css({
              transform: 'skew(30deg) translateX(calc(-50%))',
              opacity: 1
            })
            $('.v-cont .left-black-line').css({
              left:'27%',
              transform: 'skew(30deg) translateX(0)',
              opacity: 1
            })
            $('.v-cont .right-black-line').css({
              right:'0',
              transform: 'skew(-30deg) translateX(100%)',
              opacity: 0
            })
            $('.v-cont .bg-img').css({
              transform: 'translateX(-50%) translateY(30px)',
              opacity: 0,
              transitionDelay: '0s'
            })
            $('.comp-root').css({
              overflowY:'hidden',
              height: '100vh',
            })
            break;
          case 1:
            $('.v-cont .v').css({
              left: '31%'
            })
            $('.v-cont .v .green-line').css({
              backgroundColor:'transparent',
              boxShadow: '0 0 50px rgba(0,0,0,.4)',
              transform: 'skew(30deg) translateX(200px)',
              zIndex: 2,
            })
            $('.v-cont .v .green-line img').css({
              transform: 'skew(-30deg) translateX(calc(-50%))',
              opacity: 1
            })
            $('.v-cont .v .blue-line').css({
              backgroundColor:'#2d7294',
              boxShadow: '0 0 0px rgba(0,0,0,.4)',
              transform: 'skew(-30deg) translateX(-200px)'
            })
            $('.v-cont .v .blue-line img').css({
              transform: 'skew(30deg) translateX(calc(-50% + 100px))',
              opacity: 0
            })
            $('.v-cont .left-black-line').css({
              left:'0',
              transform: 'skew(30deg) translateX(-100%)',
              opacity: 0
            })
            $('.v-cont .right-black-line').css({
              right:'27%',
              transform: 'skew(-30deg) translateX(0)',
              opacity: 1
            })
            $('.v-cont .bg-img').css({
              transform: 'translateX(-50%) translateY(30px)',
              opacity: 0,
              transitionDelay: '0s'
            })
            $('.comp-root').css({
              overflowY:'hidden',
              height: '100vh',
            })
            resizeScroll ()
            break;
          case 2:
            $('.v-cont .v').css({
              left: '50%'
            })
            $('.v-cont .v .green-line').css({
              backgroundColor:'#0ea292',
              boxShadow: '0 0 50px rgba(0,0,0,.4)',
              transform: 'skew(30deg) translateX(200px)',
              zIndex: 2,
            })
            $('.v-cont .v .green-line img').css({
              transform: 'skew(-30deg) translateX(calc(-50% - 100px))',
              opacity: 0
            })
            $('.v-cont .v .blue-line').css({
              backgroundColor:'#2d7294',
              boxShadow: '0 0 0px rgba(0,0,0,.4)',
              transform: 'skew(-30deg) translateX(-200px)'
            })
            $('.v-cont .v .blue-line img').css({
              transform: 'skew(30deg) translateX(calc(-50% + 100px))',
              opacity: 0
            })
            $('.v-cont .left-black-line').css({
              left:'10%',
              transform: 'skew(30deg) translateX(0)',
              opacity: 1
            })
            $('.v-cont .right-black-line').css({
              right:'10%',
              transform: 'skew(-30deg) translateX(0)',
              opacity: 1
            })
            $('.v-cont .bg-img').css({
              transform: 'translateX(-50%) translateY(0px)',
              opacity: 1,
              transitionDelay: '1.5s'
            })
            $('.comp-root').css({
              overflowY:'auto',
              height: 'auto',
            })
            resizeScroll ()
            break;      
        }
      },
    }
  });
}

/**
 * 首页banner搜索提示框和banner切换有冲突，提示框中a链接需要点击两次才能跳转，
 * 但是点击事件不受影响，所以给banner搜索提示框中的a链接加一个点击事件跳转链接
 */
$('.index-banner .pc-swiper .common-search-hint').on('click','.item-result a',function(){
  var link = $(this).attr('href')
  window.open(link)
})

// 首页移动端banner
var mobbannerswiper = new Swiper(".index-banner .mob-swiper .mySwiper", {
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  speed: 600,
  pagination: {
    el: ".index-banner .mob-swiper .swiper-pagination",
  },
});

//点击banner按钮，到下一屏
$('.index-banner .go-btn').on('click',function(){
  $('.comp-root').css({
    overflowY:'auto',
    height: 'auto',
  })
  resizeScroll ()
  $("html, body").animate({
    scrollTop: $('.section1').offset().top
  }, {
      duration: 500,
      easing: "swing"
  });
  bannerswiper.slideTo(3)
})


//首页法律观察swiper
var indexFLGCswiper = new Swiper(".section1 .common-flgc-swiper .mySwiper", {
  slidesPerView: 1,
  spaceBetween: 30,
  breakpoints: {
    640:{
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
    },
    769: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".section1 .common-flgc-swiper .swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".section1 .common-flgc-swiper .swiper-button-next",
    prevEl: ".section1 .common-flgc-swiper .swiper-button-prev",
  },
});

//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
  var section1Top = $('.section1 .section1-swiper').offset().top + $('.section1 .section1-swiper').height()
  var section2Top = $('.section2').offset().top
  var section4Top = $('.section4').offset().top

  if(scroH > 0 && bannerswiper != ''){
    bannerswiper.disable();
  }else if(scroH <= 0 && bannerswiper != ''){
    bannerswiper.enable();
  }

  if(scroH <= 0){
    $('.section1').addClass('bg-blue')
    if($('.section2 .left .cont').length>0){
      $('.section2 .left .cont').removeClass('add-shadow')
    }
  }
  if(scroH > section1Top - viewH){
    $('.section1').removeClass('bg-blue')
  }

  if (scroH > section2Top - viewH / 3) {
    if($('.section2 .left .cont').length>0){
      $('.section2 .left .cont').addClass('add-shadow')
    }
  }

  if (scroH < section4Top-viewH) {
    $('.section4').removeClass('active')
  }
  if (scroH > section4Top - viewH / 3) {
    $('.section4').addClass('active')
  }
});